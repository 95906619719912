import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import './App.less';
import Loading from './components/UI/Loading';
import consts from './consts';
import { applicationActions } from './consts/actions';
import { doHidePopup } from './redux/modules/application';
import { route_list } from './consts/router';
import {isLoggedIn} from './redux/modules/auth';
import { Alert, Popup } from './utils/Tools';

// Route 组件如果被 Switch 组件包裹，Switch 组件会为其计算好computedMatch 属性以免重复计算。
// computeMatch主要工作就是匹配当前组件上指定的path和当前浏览器的路径是否一致，一致就渲染组件
// React-router概念理解：https://segmentfault.com/a/1190000015822513
// 在这里即使把 computedMatch 剔除 或者 屏蔽 {...rest} 程序也正常运行 ？？？*** 不知道什么原因
// function PrivateRoute({ children, location, computedMatch, ...rest }) {
function PrivateRoute({ children, location, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
          if (isLoggedIn()) {
              // 这里的children 就是 <Route {...e} />
              return (children);
          }
          else {
              return (<Redirect
                          to={{
                              pathname: consts.router.login(),
                              state: { from: location }
                          }}
                    />);
          }
      }}
    />
  );
}

// 此处代码和上面十分相似， 但是不行 不知道是什么原因
// function PrivateRoute(props) {
//     return (
//         <Route computedMatch={props.computedMatch}
//                {...props.routedata}
//             render={() => {
//                 if (isLoggedIn()) {
//                     // 这里的children 就是 <Route {...e} />
//                     return (props.children);
//                 }
//                 else {
//                     return (<Redirect
//                         to={{
//                             pathname: consts.router.login(),
//                             state: { from: props.location }
//                         }}
//                     />);
//                 }
//             }}
//         />
//     );
// }


// ？？？*** 该种方法会产生错误：调用 this.props.history.push(consts.router.create_match(getEmbaStageKey()))  错误 TypeError: this.props.history is undefined
// function PrivateRoute(props) {
//     return (
//         <Route
//             path={props.path}
//             exact={props.exact}
//             render={() => {
//                 if (isLoggedIn()) {
//                     let props_except_component = {...props};
//                     delete props_except_component.component;
//                     return (<props.component {...props_except_component} />);
//                     // return (<Route {...props} />);
//                 }
//                 else {
//                     return (
//                         <Redirect to={{pathname: consts.router.login(), state: {from: props.location}}}/>
//                     );
//                 }
//             }}
//         />
//     );
// }


class App extends Component {
  // state = {
  //   // 用户路由
  //   routes: [ ],
  // }

  // componentDidMount = async() => {
  //   this.setState({
  //     routes: route_list,
  //   })
  // }

    constructor(props) {
        super(props);

        this.state = {
            routes: [ ],
        }
    }

    async componentDidMount() {
        this.setState({
            routes: route_list,
        })
    }

  render() {
    const { application } = this.props;

    return (
      <>
        <Router>

          {/*Switch匹配一个后就停止匹配*/}
          <Switch>
            {/*{*/}
              {/*// 测试用数据生成*/}
              {/*development &&*/}
              {/*<Route path="/test/matchData" component={() => <MatchDataTest />} />*/}
            {/*}*/}

            {/* 登录页 */}
            <Route path={consts.router.login()} component={consts.router.Login} />

            {/* 扫码登录页 */}
            {/*<Route path={consts.router.login_qrcode()} component={consts.router.LoginQrcode} />*/}

            {
              this.state.routes.map((e, i) => {
                const obj = {...e};
                delete obj.component;
                return <PrivateRoute key={i} {...obj}>
                  <Route {...e} />
                </PrivateRoute>
              })
            }

              {/*{*/}
                  {/*this.state.routes.map((e, i) => {*/}
                      {/*const obj = {...e};*/}
                      {/*delete obj.component;*/}
                      {/*return <PrivateRoute key={i} routedata={obj}>*/}
                          {/*<Route {...e} />*/}
                      {/*</PrivateRoute>*/}
                  {/*})*/}
              {/*}*/}

            {/*{*/}
                {/*this.state.routes.map((e, i) => {*/}
                  {/*return <PrivateRoute {...e} />;*/}
                {/*})*/}
            {/*}*/}

            {/* 404 */}
            <Route path="*" component={consts.router.NotFound} />
            
          </Switch>
        </Router>


        { // loading 
          <Loading show={application.requestQuantity > 0} />
        }

        { // 弹框 带有按钮信息框
            <Popup show={application.popupShow}
                   classnames={application.popupClassnames}
                   type={application.popupType}
                   status={application.popupStatus}
                   icon={application.popupIcon}
                   title={application.popupTitle}
                   subTitle={application.popupSubTitle}
                   okHandle={() => {
                       application.popupOkHandle();
                   }}
                   cancelHandle={() => {
                       doHidePopup()
                   }}
            />
        }

        { // 黑色弹框
            <Alert show={application.alertShow}
                   type={application.alertType}
                   messasge={application.alertMessage} />
        }

      </>
    );
  }
}

export default connect(state => {
    return {
        application: state.application,
    }
}, dispatch => {
    return {
        applicationActions: bindActionCreators(Object.assign({}, applicationActions), dispatch),
    }
})(App);

