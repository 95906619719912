// B端代码的调试在FireFox 浏览器中进行 =》检查元素 =》调试器

// // Yubin IP
// export const stock_APIServer = 'http://127.0.0.1:5555/api';
// // Python 服务 websocket 连接
// export const URL_Python_Service = "ws://localhost:6926";
// export const URL_Python_GrabTask = "ws://localhost:9355";
// export const URL_ExcelFile = "http://localhost/";

//Server IP
export const stock_APIServer = 'http://119.12.161.180:5555/api';
// Python 服务 websocket 连接
export const URL_Python_Service = "ws://localhost:6926";
export const URL_Python_GrabTask = "ws://localhost:9355";


// 散点图存放的目录
// export const scatter_diagram_path = "../assets/z_temp";

// ******** ***************************************** ******** //

// 将ruleids添加到权限组中
export const addRuleIdsToRole = `${stock_APIServer}/auth/addRuleIdsToRole`;

// 获取字典接口的通用头部
export const DictAPIHead = `${stock_APIServer}/common/`;

// 登录
export const signin = `${stock_APIServer}/auth/signin`;

// 登录
export const changePwd = `${stock_APIServer}/auth/changePwd`;

// 获取查询字段定义
export const getQueryDef = `${stock_APIServer}/common/getQueryDef`;

// 获取列表字段定义
export const getListDef = `${stock_APIServer}/common/getListDef`;

// 获取连线图计算类型
export const getPlotFitcodes = `${stock_APIServer}/common/getPlotFitcodes`;

// 获取 某个指标类型下面的 计算类型列表
export const getPlotList = `${stock_APIServer}/common/getPlotList`;

// 获取基础交易  不分页列表
export const getStockList = `${stock_APIServer}/stockui/getStockList`;

// 遍历 特征值计算设置表 获取所有的特征值名称 返回数组
export const getAllFeatureNames = `${stock_APIServer}/stockui/getAllFeatureNames`;

// 获取所有的特征值分类字段名称 返回数组
export const getAllClassifyNames = `${stock_APIServer}/stockui/getAllClassifyNames`;

// 遍历 标签值计算设置表 获取所有的标签名称 返回数组
export const getAllLabelNames = `${stock_APIServer}/stockui/getAllLabelNames`;

// 获取股票交易数据，和扩展的特征值 和对应的标签 返回 按日期倒序的json数组
export const getStockFeatureLabels = `${stock_APIServer}/stockui/getStockFeatureLabels`;

// 获取每只股票对应的表的交易数据  分页列表
export const getStockDailyLineList = `${stock_APIServer}/stockui/getStockDailyLineList`;

// 获取 收盘价特征值  分页列表 --- Api程序没有该接口
export const getClosePriceSummaryList = `${stock_APIServer}/stockui/getClosePriceSummaryList`;

// 获取 特征值组 - 根据特征值组的名称串
export const getFeatureGroupList = `${stock_APIServer}/stockui/getFeatureGroupList`;

// 获取 分类的概率分布 不分页列表
export const getClassifyProbList = `${stock_APIServer}/stockui/getClassifyProbList`;

// 获取 数据抓取任务列表
export const getGrabDataTaskList = `${stock_APIServer}/grabapi/getGrabDataTaskList`;

// 设置 数据抓取任务
export const setupGrabTask = `${stock_APIServer}/grabapi/setupGrabTask`;

// 设置 数据抓取任务
export const getGrabTaskById = `${stock_APIServer}/grabapi/getGrabTaskById`;

// 设置 数据抓取任务
export const updateGrabTaskById = `${stock_APIServer}/grabapi/updateGrabTaskById`;

////////////////////////////////////////////////////////////////////////////////////
// 初始化返回用户权限
export const getMenuRoleList = `${stock_APIServer}/auth/getMenuRoleList`;

// 权限用户列表
export const userRoleList = `${stock_APIServer}/auth/userRoleList`;

// 获取用户权限列表
export const requestUserAuthList = `${stock_APIServer}/auth/requestUserAuthList`;

// 获取用户组列表
export const getCmsAuthRoleList = `${stock_APIServer}/auth/getCmsAuthRoleList`;

// 将用户从角色管理组删除，并删除用户
export const delMemberWithRole = `${stock_APIServer}/auth/delMemberWithRole`;

// 保存用户信息
export const saveUser = `${stock_APIServer}/auth/saveUser`;

// 获取每只股票对应的浮点数特征值类别数字大小范围信息
export const getClassifyListByCode = `${stock_APIServer}/stockui/getClassifyListByCode`;

// 获取每只股票对应的多个交易日的整体特征值
export const getSummaryListByCode = `${stock_APIServer}/stockui/getSummaryListByCode`;
// 获取 模型列表
export const getModelList = `${stock_APIServer}/stockui/getModelList`;
// 获取 详情数据列表
export const getDetailList = `${stock_APIServer}/stockui/getDetailList`;
// 获取 特征值计算设置数据列表
export const getCalcSetupList = `${stock_APIServer}/stockui/getCalcSetupList`;
// 获取 特征值计算设置数据
export const getCalcSetupById = `${stock_APIServer}/stockui/getCalcSetupById`;
// 新增修改设置数据
export const updateCalcSetupById = `${stock_APIServer}/stockui/updateCalcSetupById`;
// 删除设置数据
export const delCalcSetupById = `${stock_APIServer}/stockui/delCalcSetupById`;
// 根据类型获取字典列表
export const getDictValueListByType = `${stock_APIServer}/common/getDictValueListByType`;
// 获取相关系数详情
export const getCorrDetailList = `${stock_APIServer}/stockui/getCorrDetailList`;
// 获取相关系数任务
export const getCorrTaskList = `${stock_APIServer}/stockui/getCorrTaskList`;
// 删除相关系数任务
export const deleteCorrTask = `${stock_APIServer}/stockui/deleteCorrTask`;






