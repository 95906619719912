import store from '../index';

const initialState = {
    requestQuantity: 0,  // 当前应用中正在进行的API请求数
    alertShow: false,                   // 是否显示 alert
    alertType: 'success',               // alert 类型
    alertMessage: '',                   // alert 消息
    popupShow: false,                    // popup 消息状态
    popupType: 'info',                      // popup 类型 ask 询问确认取消； info 警告确认；
    popupStatus: 'info',                    // 结果的状态,决定图标和颜色 'success' | 'error' | 'info' | 'warning'| '404' | '403' | '500'
    popupClassnames: '',
    popupIcon: '',
    popupTitle: '',
    popupSubTitle: "",
    popupOkHandle: () => {},
  };
  
  // action types
  export const types = {
  // 设置redux数据
    START_REQUEST: "APP/START_REQUEST",   // 开始发送请求
    FINISH_REQUEST: "APP/FINISH_REQUEST", // 请求结束
    SET_REDUX_DATA: "UI/SET_STORE_DATA", // 设置ui数据
  };

  const setReduxData = (data) => ({
    type: types.SET_REDUX_DATA,
    data
  });

  // action creators
  export const actions = {
    startRequest: () => ({
      type: types.START_REQUEST
    }),
    finishRequest: () => ({
      type: types.FINISH_REQUEST
    }),
    showAlert: (alertMessage, alertType = 'success', timeout) => {
        return (dispatch, getState) => {
            dispatch(setReduxData({
                alertShow: true,
                alertMessage,
                alertType
            }));
            setTimeout(() => {
                dispatch(setReduxData({
                    alertShow: false,
                    alertMessage: '',
                    alertType: 'success'
                }))
            }, timeout);
        }
    },
    hidePopup: () => {
        return (dispatch, getState) => {
            dispatch(setReduxData({
                popupShow: false,
                popupType: 'info',
                popupTitle: '',
                popupStatus: 'info',
                popupSubTitle: '',
                popupOkHandle: () => {}
            }))
        }
    },
    showPopup: (classnames,type, status, icon, title, subTitle, ok) => {
        return (dispatch, getState) => {
            dispatch(setReduxData({
                popupClassnames: classnames,
                popupShow: true,
                popupType: type,
                popupIcon: icon,
                popupTitle: title,
                popupStatus: status,
                popupSubTitle: subTitle,
                popupOkHandle: ok
            }))
        }
    },

  };
  
  // reducers
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case types.START_REQUEST:
        // 每接收一个API请求开始的action，requestQuantity加1
        return { ...state, requestQuantity: state.requestQuantity + 1 };
      case types.FINISH_REQUEST:
        // 每接收一个API请求结束的action，requestQuantity减1
        return { ...state, requestQuantity: state.requestQuantity - 1 };
      case types.SET_REDUX_DATA:
            return Object.assign({}, state, {...action.data});
      default:
        return state;
    }
  };
  
  export const doStartRequest = () => {
    store.dispatch(actions.startRequest())
  };

  export const doFinishRequest = () => {
    store.dispatch(actions.finishRequest());
  };

  export const doAlert = (message, type = 'success', timeout = 2000) => {
      store.dispatch(actions.showAlert(message, type, timeout));
  };

  export const doPopup = ({classnames='', type = 'info', status='info', icon=null,  title = '提示信息', subTitle, ok = store.dispatch(actions.hidePopup())}) => {
      store.dispatch(actions.showPopup(classnames, type, status, icon, title, subTitle, ok));
  };

  export const doHidePopup = () => {
      store.dispatch(actions.hidePopup());
  };

  export default reducer;

