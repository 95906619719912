import React from "react";
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import './Loading.css';

const Loading = (props) => {
  return  props.show ?
  (
    <div className="UI__container">
      <Spin tip="加载中..." size="large" />
    </div>
  ) : null;
}
Loading.defaultProps = {
  show: false
}
Loading.propTypes = {
  show: PropTypes.bool,
}

export default Loading;
