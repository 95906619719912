import asyncComponent from "../utils/AsyncComponent";
import mainmenuComponent from "../utils/MainmenuComponent";

// 首页
export const home = '/';
export const login = () => '/login';
export const changePwd = () => '/changePwd';

/////////////////defined by yubin ///////////////////////////
export const Login = asyncComponent(() => import("../pages/Login"));
export const ChangePwd = asyncComponent(() => import("../pages/ChangePwd"));
export const Home = mainmenuComponent(asyncComponent(() => import("../pages/Home")));

// export const DataStasti = mainmenuComponent(staticDataStasti);
export const QueryData = mainmenuComponent(asyncComponent(() => import("../pages/QueryData")));
export const ClosePrice = mainmenuComponent(asyncComponent(() => import("../pages/backup/ClosePrice")));
export const QueryFeature = mainmenuComponent(asyncComponent(() => import("../pages/QueryFeature")));
export const QueryModel = mainmenuComponent(asyncComponent(() => import("../pages/QueryModel")));
export const GrabBaidu = mainmenuComponent(asyncComponent(() => import("../pages/backup/GrabBaidu")));
export const QueryClassifyProb = mainmenuComponent(asyncComponent(() => import("../pages/QueryClassifyProb")));
export const QuerySummary = mainmenuComponent(asyncComponent(() => import("../pages/QuerySummary")));
export const QueryClassify = mainmenuComponent(asyncComponent(() => import("../pages/QueryClassify")));
export const CalcSetup = mainmenuComponent(asyncComponent(() => import("../pages/CalcSetup")));
export const QueryCorr = mainmenuComponent(asyncComponent(() => import("../pages/QueryCorr")));
// 静态加载示例
// 静态加载组件，这样在刚开始调试的时候，就可以看到该文件
// import staticGeneralCreateMatch from "../general_pages/GeneralCreateMatch"
// export const GeneralCreateMatch = connectGeneralRoute(staticGeneralCreateMatch);
// 动态加载
// export const GeneralCreateMatch = connectGeneralRoute(asyncComponent(() => import("../general_pages/GeneralCreateMatch")));

export const System_auth_setter = mainmenuComponent(asyncComponent(() => import('../pages/System/AuthSetter')));
export const System_auth_user = mainmenuComponent(asyncComponent(() => import('../pages/System/AuthUser')));
export const System_auth_role = mainmenuComponent(asyncComponent(() => import('../pages/System/AuthRole')));

/**
 * 路由表
 * 仅负责路由功能(403、404、200)
 * 菜单和功能由菜单表负责
 * 属性：
 *    title
 *    exact 设置exact精确匹配
 *    path
 *    component
 *    matchPath 当path中有存在路径常量则必须要加上该参数，并根据常量依次加入
 *  （注意：数组中的每个值结尾无须加 /，如用户请求的地址存在与path或matchPath中，但用户路由表不存在， 即可判定为禁止访问403）
 */
export const route_list = [
    {
        key: 'home',
        title: '首页',
        // 由于 path: home='/' 将会匹配所有的链接，所以必须将其设置成 exact: true 否则所有的页面都会用首页渲染。
        exact: true,
        path: home,
        component: Home,
    },
    {
        exact:false,
        root: true,
        icon: '',
        title: '登录页',
        path: '/login',
        component: Login,
    },
    {
        exact:false,
        icon: '',
        title: '修改密码',
        path: '/changePwd',
        component: ChangePwd,
    },
    {
      exact:false,
      title: '数据指标 - 原始交易',
      path: '/stockdata/dataquery',
      component: QueryData,
    },
    {
        exact:false,
        title: '数据指标 - 收盘价指标',
        path: '/stockdata/closeprice',
        component: ClosePrice,
    },
    {
        exact:false,
        title: '数据指标 - 交易特征值',
        path: '/stockdata/queryfeature',
        component: QueryFeature,
    },
    {
        exact:false,
        title: '数据指标 - 特征值配置',
        path: '/stockdata/CalcSetup',
        component: CalcSetup,
    },
    {
        exact:false,
        title: '概率分布 - 标签分类概率',
        path: '/prob/QueryClassifyProb',
        component: QueryClassifyProb,
    },
    {
        exact:false,
        title: '概率分布 - 时间段概览',
        path: '/prob/QuerySummary',
        component: QuerySummary,
    },
    {
        exact:false,
        title: '模型评估 - SVM计算结果',
        path: '/model/QueryModel',
        component: QueryModel,
    },
    {
        exact:false,
        title: '模型评估 - 混合高斯参数',
        path: '/model/QueryClassify',
        component: QueryClassify,
    },
    {
        exact:false,
        title: '数据抓取 - 百度抓取测试',
        path: '/autograb/grabbaidu',
        component: GrabBaidu,
    },

    {
        title: '用户权限管理',
        path: '/system/auth_user',
        component: System_auth_user,
    },
    {
        title: '角色权限管理',
        exact: true,
        path: '/system/auth_role',
        component: System_auth_role,
    },
    {
        title: '角色权限编辑',
        path: '/system/auth_setter',
        component: System_auth_setter,
    },
    {
        exact:false,
        title: '相关系数 - 任务列表',
        path: '/model/QueryCorr',
        component: QueryCorr,
    },
];


