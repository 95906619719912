import { combineReducers } from "redux";
import application from "./application";
import auth from "./auth";

// 合并所有模块的reducer成一个根reducer
const rootReducer = combineReducers({
    application,
    auth,
});

export default rootReducer;

export const getAppState = (state) => state.application;
export const getAuthState = (state) => state.auth;
