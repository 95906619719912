import React from 'react';
import {Link} from 'react-router-dom';
import { Layout, Menu } from 'antd';
import * as otherConsts from '../consts/otherConsts';
import consts from '../consts';
import './Header.css';
import { getHeadimg } from '../redux/modules/auth';
import { exitAction, changePwdAction, hasArrayData} from '../utils/Tools';

const { Header } = Layout;

function MatchHeader({menu_list = []}) {
  const menus = () => {
    const nodes = [];
    if (hasArrayData(menu_list)) {
      menu_list.forEach((item, index) => {
        let children = []
        //  目前只支持 二级 菜单
        if (item.children) {
            children = item.children.map(e => {
              return (
                <Menu.Item key={`setting:${e.id}`}>
                  {/* 
                    2020年01月20日14:39:06
                    使用window跳转，重置切换 mba路由 和 通用路由
                  */}
                  <Link to={'#'} onClick={() => {
                    window.location.href = e.path;
                  }}>
                    {e.title}
                  </Link>
                </Menu.Item>
              )
              
          })
        }
        // nodes.push(
        //   <Menu.SubMenu key={index.toString()} className="Header_menu_item_active" title={<span className="Header_submenu_title_wrapper">{item.title}</span>}>
        //
        //    <Menu.ItemGroup>{ children }</Menu.ItemGroup>
        //
        //   </Menu.SubMenu>
        // )

        // className="Header_menu_item_active" 没有使用
        // 将 span 改成 div 也可以
        nodes.push(
            <Menu.SubMenu key={index.toString()}  title={<span className="Header_submenu_title_wrapper">{item.title}</span>}>

                <Menu.ItemGroup>{ children }</Menu.ItemGroup>

            </Menu.SubMenu>
        );

      });
    }
    

    return nodes;
  }

    return (
      <Header className={'Header_container'}>
      <img className={'Header_logo'} src={otherConsts.logo}
           onClick={() => {
               window.location.href = consts.router.home;
           }}/>
      <Menu mode="horizontal" className={'Header_menu'}>
        {menus()}
        
    </Menu>
    <div className="Header_menu_headimg">
      <img className={'Header_headImg'} src={getHeadimg()} />
      <ul className="Header_menu_headimg_submenu">
        <li onClick={()=> { exitAction();}}>退出</li>
        <li onClick={()=> { changePwdAction();}}>修改密码</li>
      </ul>
    </div>
    </Header>
    )
}


export default MatchHeader;

