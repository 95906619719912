import React from "react";
import { Result, Button } from 'antd';
import consts from "../../consts";
function NotPermission() {
  return (
    <Result
    status="403"
    title="尚未授权"
    subTitle="对不起, 您尚未被授权访问此网页."
    extra={<Button type="primary" onClick={() => {
      window.location.href = consts.router.home;
    }}>返回首页</Button>}
  />
  )
}

export default NotPermission;
