import React, { Component } from "react";
import PropTypes from 'prop-types';
import _ from 'lodash';
import store from '../redux';
import moment from 'moment';

import {Select} from 'antd';

import uuid from 'uuid';
import { get, post } from './request';
import consts from '../consts';
import url from 'url';
import queryString from "query-string";
import { Skeleton, Icon, Result, Button } from 'antd';
import { authActions} from '../consts/actions';
import { getUserAuthUrls, isLoggedIn } from '../redux/modules/auth';
import { matchRouteType } from '../consts/router';
import PKLayout from "../components/Layout";
import { connect } from 'react-redux';
import Loading from "../components/UI/Loading";
import NotPermission from "../pages/Error/403";

import './Tools.css';
import * as otherConsts from "../consts/otherConsts";
import * as API from "../consts/API";

const { Option } = Select;
let icon = "check-circle";

// 临时显示的 黑色弹框 几秒钟后自动关闭
export function Alert({show, messasge, type}) {
    if (type === 'warn') {
        icon = "exclamation-circle"
    } else if (type === 'error') {
        icon = "close-circle";
    } else if (type === 'success') {
        icon = "check-circle"
    }
    return show ?
        (
            <div className="UI__container">
                <div className="Alert__alerts">
                    <Icon type={icon} className="Alert__icon"/>
                    <span className="Alert__span">{messasge}</span>
                </div>
            </div>
        ) : null;
}

Alert.propTypes = {
    show: PropTypes.bool,
    type: PropTypes.string,
}

// 弹框 带有按钮信息框
export function Popup({show, mark = true, type = 'info', ...otherProps}) {
    if (!show) return null;
    return (
        <div className={mark ? 'UI__container black_mark' : 'UI__container'} onClick={otherProps.cancelHandle}>
            <div className="Popup__wrapper" onClick={e => { e.stopPropagation(); }}>
                <Icon className="Popup__close" type="close" onClick={otherProps.cancelHandle} />
                {
                    type === 'ask' &&
                    <Ask {...otherProps} />
                }
                {
                    type === 'info' &&
                    <Info {...otherProps} />
                }
            </div>

        </div>
    )
}

Popup.propTypes = {
    // 组件是否显示
    show: PropTypes.bool,
    // 是否带有黑色遮罩层 默认有
    mark: PropTypes.bool,
    // 消息类型 ask 确认取消按钮 info 单个确认按钮 默认为info
    type: PropTypes.string,
    // 标题
    title: PropTypes.string,
    // 子标题
    subTitle: PropTypes.string,
    // 确认按钮点击函数，
    // 如果类型为 ask则为确定，如果类型为 info 则为按钮函数
    okHandle: PropTypes.func,
    // 取消按钮点击函数
    cancelHandle: PropTypes.func,
    // 结果的状态,决定图标和颜色  'success' | 'error' | 'info' | 'warning'| '404' | '403' | '500'
    status: PropTypes.string,
}

function Ask(props) {
    return (
        <Result
            className={props.classnames}
            status={props.status}
            title={props.title}
            icon={props.icon}
            subTitle={props.subTitle}
            extra={[
                <Button type="primary" key="ok" onClick={() => {
                    props.cancelHandle();
                    props.okHandle()
                }}>
                    确定
                </Button>,
                <Button key="cancel" onClick={props.cancelHandle}>取消</Button>,
            ]}
        />
    )
}

function Info(props) {
    return (
        <Result
            status="info"
            title={props.title}
            subTitle={props.subTitle}
            extra={
                <Button type="primary" key="ok" onClick={props.cancelHandle}>确定</Button>
            }
        />
    )
}

export const chunk = (arr, n) => _.chunk(arr, n);

export const log = (data) => {
  console.log(data)
}

/**
 * 判断两个对象是否相等
 */
export const isEqual = (o1, o2) => _.isEqual(o1, o2);

/**
 * 判断是否是数组 & 长度 > 0
 * @param {array} arr 查询的数组
 */
export const hasArrayData = (arr) => {
  return _.isArray(arr) && arr.length > 0;
}
/**
 * 判断是否是数组 & 长度 <= 0
 * @param {array} arr 查询的数组
 */
export const lessArrayData = (arr) => {
  return Array.isArray(arr) && arr.length <= 0;
}

/**
 * 判断对象是否为空
 * @param {object} obj 要查询的对象
 */
export const hasObjectData = (obj) => {
  return !!obj && Object.keys(obj).length > 0;
}
/**
 * 判断是否是方法
 */
export const isFunction = (func) => {
  return func && Object.prototype.toString.call(func) === "[object Function]";
}
/**
 * 数字转换为中文
 * @param {number} num 需转换的数字
 */
export const toChineseNum = (num) => {
  const chineseNums = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  let arr = [];
  let num_arr = (num + '').split('').map(Number);
  for (let i = 0; i < num_arr.length; i++) {
    arr.push(chineseNums[num_arr[i]]);
  }
  return arr.join('');
}
/**
 * 精确小数位
 */
export const formatFloat = (f, digit) => {
  if (digit === void 0) { digit = 1 }
  var m = Math.pow(10, digit);
  return Math.round(f * m, 10) / m;
}

/**
 * 获取url地址中的参数值
 * @param {string} variable uri中的参数
 */
export const getUrlParam = (variable) => {
  const _href = window.location.href;
  return url.parse(_href, true).query[variable];
}

/**
 * 带有url参数，返回 uri + 参数
 */
export const withUrlParams = (uri) => {
  const _querystring = queryString.parseUrl(window.location.href);
  return uri + '?' + queryString.stringify(_querystring.query);
}
/**
 * 参数处理
 */
export const urlUtils = {
  setParams: (obj = {}) => queryString.stringify(obj),
  isMatchPath: (path = '') => window.location.pathname.indexOf(path) >= 0,
  getParam: (name) => getUrlParam(name),
  parse: (str) => queryString.parse(str),
}

/**
 * 插入id到数组数据
 * @param {array} data 数组
 */
export const insertIdToArrayData = (data) => {
  return data.map(item => {
    item.id = uuid();
    return item;
  })
}

/**
 * 合并 时间日期
 * @param _date 日期
 * @param _time 时间
 */
export const mergeDateTime = (_date, _time) => {
  const _d = moment(_date);
  const _t = moment(_time);
  const year = _d.year();
  const month = _d.month();
  const date = _d.date();
  const hour = _t.hour();
  const minute = _t.minute();
  const second = _t.second();
  return moment({
    year,
    month,
    date,
    hour,
    minute,
    second
  })
}

/**
 * 请求是否成功
 * @param {Object} res 请求回调
 */
export const fetchSuccess = (res) => {
  const status = (_.get(res, 'data.status')) + '';
  return status === '1' || status === '200';
};


export {
  post,
  get
}

/**
 * @todo 授权util
 * 是否是授权页面
 */
export const isAuthPage = () => {
  //  使用全目录名称 来检查权限
  const pathname = window.location.pathname + window.location.search;
  const userUrls = getUserAuthUrls();
  return userUrls.join('').indexOf(pathname) >= 0;
}


/**
 * 用户退出
 */
export const exitAction = () => {
  store.dispatch(authActions.logout())
}

// 修改密码
export const changePwdAction = () => {
    store.dispatch(authActions.changePwd())
}

/**
 * cookie工具类
 */
export const cookieUtil = {
  set: function (key, val, time) {//设置cookie方法
    var date = new Date(); //获取当前时间
    var expiresDays = time;  //将date设置为n天以后的时间
    date.setTime(date.getTime() + expiresDays * 24 * 3600 * 1000); //格式化为cookie识别的时间
    document.cookie = key + "=" + val + ";expires=" + date.toGMTString();  //设置cookie
  },
  get: function (key) {//获取cookie方法
    /*获取cookie参数*/
    var getCookie = document.cookie.replace(/[ ]/g, "");  //获取cookie，并且将获得的cookie格式化，去掉空格字符
    var arrCookie = getCookie.split(";")  //将获得的cookie以"分号"为标识 将cookie保存到arrCookie的数组中
    var tips;  //声明变量tips
    for (var i = 0; i < arrCookie.length; i++) {   //使用for循环查找cookie中的tips变量
      var arr = arrCookie[i].split("=");   //将单条cookie用"等号"为标识，将单条cookie保存为arr数组
      if (key === arr[0]) {  //匹配变量名称，其中arr[0]是指的cookie名称，如果该条变量为tips则执行判断语句中的赋值操作
        tips = arr[1];   //将cookie的值赋给变量tips
        break;   //终止for循环遍历
      }
    }
    return tips;
  },
  delete: function (key) { //删除cookie方法
    var date = new Date(); //获取当前时间
    date.setTime(date.getTime() - 10000); //将date设置为过去的时间
    document.cookie = key + "=v; expires =" + date.toGMTString();//设置cookie
  }
}

/**
 * 转换字符串中的名称(<pk_at>@名称</pk_at>)
 * @param str 需要转换的字符串
 * @param clickHandle 点击转换文字的事件 参数为转换的文字
 */
export const convertNameByString = (str, clickHandle = (name) => {
  // 根据昵称查找id，并跳转到个人页
  get(consts.API.getMemberidByNickname, {nickname: name}).then(res => {
    if (fetchSuccess(res)) {
      const memberid = res.data.data.memberid ^ 0;
      if (memberid) {
        window.location.href = consts.router.user() + '/?u=' + memberid
      }
    }
  })
}) => {
  // 将 @name 和 普通文字进行拆分
  let nameAtFlagString = "(<pk_at>.*?</pk_at>)";
  let nameAtReg = new RegExp(nameAtFlagString);
  let nameSplitArray = str.split(nameAtReg).map((e, i) => {
    if (nameAtReg.test(e)) {
      let name = e.replace(/\<pk_at\>|\<\/pk_at\>/g, "");
      return <span key={i} className="pk_at" onClick={(e) => {
        e.stopPropagation();
        name = name.replace('@', '');

        clickHandle(name);

      }}>{name}</span>
    }
    return e;
  })

  return (
    nameSplitArray
  )
};

/**
 * 限制冬天内容长长度，不限于@xxx
 * @param {string} content 内容
 * @param {number} limitLen 限制长度 默认36
 * @returns array
 */
export const limitTrendContentLength = (content, limitLen = 36) => {
  let limitArr = []

  // 限制字数
  for(let i = 0; i < content.length; i ++) {
    const element = content[i];
    // 小于限制数量则不push
    if (limitLen > 0) {
      if (!_.get(element, 'type')) {
        limitArr.push(element.slice(0, limitLen));
        limitLen -= element.length;
      }
      else {
        // 处理格式化的文字 如 @xxx，这是一个对象
        const word = _.get(element, 'props.children');
        if (limitLen - word.length >= 0) {
          limitArr.push(element);
          limitLen -= word.length;
        }
      }
    }
  }
  if (limitLen < 0) {
    limitArr.push('...');
  }
  return limitArr;
}

export const dateformat = (extra, date) => {
    let D = new Date(date);
    let time = {
        "Y": D.getFullYear(),
        'm': D.getMonth() + 1,
        'd': D.getDate(),
        'H': D.getHours(),
        'i': D.getMinutes(),
        's': D.getSeconds()
    }
    let key = extra.split(/\W/);
    let _date;
    for (let k of key) {
        time[k] = time[k] < 10 ? "0" + time[k] : time[k]
        _date = extra.replace(k, time[k])
        extra = _date;
    }
    return _date;
}


export const router_values = Object.values(consts.router);


export const getScrollTop = () => {
  var scrollTop = 0;
  if (document.documentElement && document.documentElement.scrollTop) {
      scrollTop = document.documentElement.scrollTop;
  } else if (document.body) {
      scrollTop = document.body.scrollTop;
  }
  return scrollTop;
}

/**
 * 创建骨架屏
 */
export const createSkeleton = (num = 0) => {
    if (num > 0) {
        return Array(num).fill(' ').map((item, index) => (
            <Skeleton key={'Skeleton' + index} loading={true} active avatar></Skeleton>
        ))
    } else {
        return <Skeleton active />
    }
}

// 如果放在 Tools 中引用，npm run build 生成的build目录在服务器上没有办法单独运行，
// 需要 抽取出来形成单独文件 运行才可以
// export function asyncComponent(importComponent) {
//     class AsyncComponent extends Component {
//         constructor(props) {
//             super(props);
//             this.state = {
//                 component: null,
//                 userUrls: []
//             };
//         }
//
//         // componentDidMount() {
//         //   importComponent().then((mod) => {
//         //     this.setState({
//         //       // 同时兼容ES6和CommonJS的模块
//         //       component: mod.default ? mod.default : mod,
//         //       userUrls: getUserAuthUrls()
//         //     });
//         //   });
//         // }
//
//         async componentDidMount() {
//             // 实际执行的是 import("../pages/Login") 条件加载 动态加载
//             // import() 是一个异步函数，返回一个Promise，在加载完成后返回模块对象
//             const C = await importComponent();
//             this.setState({
//                 // 同时兼容ES6和CommonJS的模块
//                 component: C.default,
//                 // userUrls: getUserAuthUrls()
//             });
//         }
//
//         render() {
//             // const C = this.state.component;
//             // return C ? <C {...this.props} /> : <>
//             // {/* 网络慢加载骨架屏 */}
//             //   {createSkeleton(8)}
//             // </>;
//
//             if (this.state.component) {
//                 return (<this.state.component {...this.props} />);
//             }
//             else {
//                 // 如果没有加载成功，则显示空白动画页面
//                 return (<>{createSkeleton(8)}</>);
//             }
//         }
//     }
//
//     return AsyncComponent;
// }
//
// export function mainmenuComponent(WrappedComponent) {
//     return class mainmenuComponent extends React.Component {
//         render() {
//             const otherProps = {};
//             Object.assign(otherProps, urlUtils.parse(this.props.location.search))
//             return <PKLayout {...this.props}><WrappedComponent {...otherProps} {...this.props} /></PKLayout>
//         }
//     };
// }

export function WidthAuth(WrappedComponent) {
    @connect(
        state => ({
            auth: state.auth
        })
    )
    class WidthAuth extends Component {
        render() {
            const authComplete = this.props.auth.authComplete;

            if (!authComplete) {
                return <Loading show={true} />;
            }
            if (isAuthPage()) {
                return <WrappedComponent isAuthPage={isAuthPage()} {...this.props} />;
            }
            else {
                return <NotPermission />;
            }

            // return <WrappedComponent isAuthPage={isAuthPage()} {...this.props} />;
        }
    }
    return WidthAuth;
};

// 表单label required 是否必填 text 文字 optional 可选的 样式在 App.less
export const FormLabel = (props) => {
    return (
        <div className={'Tools_FormLabel_wrap'}>
            {
                props.required && <b className={'Tools_FormLabel_required_flag'}>*</b>
            }

            <span className={'Tools_FormLabel_text'}>{props.text}</span>

            {
                props.optional &&
                <span className={'Tools_FormLabel_title_desc'}>(选填)</span>
            }
        </div>
    )
}

// 返回下拉框的字典选项列表
export const render_select_option = (item_list) => {
    if (hasArrayData(item_list)) {
        let option_list = item_list.map((item, index) => {
            return (
                <Option value={item.code}>{item.name}</Option>
            );
        });

        // 将空选项放到第一个
        option_list.unshift((
            <Option value={""}>{""}</Option>
        ));

        return option_list;
    }

    return (null);
}

// 根据列表, 统计描述 中所有字典的内容
export const get_def_dict = async (listdefs, dict) => {
    let dict_levelone = dict;

    for (let i = 0; i < listdefs.length; i++) {
        // 获取一级字典的内容
        if (listdefs[i].type == otherConsts.field_type_list.thirddict
            || listdefs[i].type == otherConsts.field_type_list.thirddict_selection
            // 如果是自定义的类型，也可以顺带 加入一级字典如 ：'分案历史列表', 'casedisp_detail',  '分案比例',            '03_10', '35',  'getDepartments'
            || (listdefs[i].type == otherConsts.field_type_list.customized_display
                && listdefs[i].dictname.length > 0)) {
            // 如果字典不存在，则到 后台查询
            if (dict_levelone[listdefs[i].fieldname] == undefined) {
                let url = API.DictAPIHead + listdefs[i].dictname;
                const res = await get(url, {});
                let dict_list = res.data.data;
                dict_levelone[listdefs[i].fieldname] = dict_list;
            }
        }
        // 获取二级字典所有的条目（就是父字典的内容为 * 时，将子字典的所有内容都加入）
        else if (listdefs[i].type == otherConsts.field_type_list.thirddict_child) {
            // 如果字典不存在，则到 后台查询
            if (dict_levelone[listdefs[i].fieldname] == undefined) {
                let url = API.DictAPIHead + listdefs[i].dictname;
                const res = await get(url, {parent_code: "*"});
                let dict_list = res.data.data;
                dict_levelone[listdefs[i].fieldname] = dict_list;
            }
        }
    }

    return dict_levelone;
}

// 通过编码，获取字典的名称
export const get_dictname_bycode = (dict, fieldname, code) => {
    if (hasArrayData(dict[fieldname])) {
        const dictlist = dict[fieldname];
        for (let i = 0; i < dictlist.length; i++) {
            if (dictlist[i].code == code) {
                return dictlist[i].name;
            }
        }
    }

    return code;
}

export const sleep = (ms) => {
    var temple = new Promise(
        (resolve)=>{
            setTimeout(resolve,ms);
        });
    return temple;
}

