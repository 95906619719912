import React, { Component } from "react";
import {urlUtils} from "./Tools";
import PKLayout from "../components/Layout";

export default function mainmenuComponent(WrappedComponent) {
    return class mainmenuComponent extends React.Component {
        render() {
            const otherProps = {};
            Object.assign(otherProps, urlUtils.parse(this.props.location.search))
            return <PKLayout {...this.props}><WrappedComponent {...otherProps} {...this.props} /></PKLayout>
        }
    };
}