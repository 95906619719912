import * as actions  from './actions';
import * as API from './API';
import * as router from './router';
import * as otherConsts from './otherConsts';

export default {
  actions,
  API,
  router,
  otherConsts,
}
