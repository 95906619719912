import React from 'react';
import { Layout } from 'antd';
import store from '../redux/index';
import Header from './Header';
import './Layout.css';
import { getAuthState } from '../redux/modules';
const { Content } = Layout;

/**
 * 基础外框
 */
function PKLayout(props) {
    return (
        <div className="Layout_container scrollbar_wrapper">
            {/* 正常菜单 */}
            <Header {...props} menu_list={getAuthState(store.getState()).menu_list} />

            <Content>
                {props.children}
            </Content>
        </div>
    );
}

export default PKLayout;

