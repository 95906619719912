import React from 'react';
import ReactDOM from 'react-dom';
import { LocaleProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { Provider } from "react-redux";
import promise from 'es6-promise';
import store from "./redux";
import './index.css';
import _ from 'lodash';
import App from './App';

moment.locale('zh-cn');
promise.polyfill();

(function() {
  var scale = 1 / devicePixelRatio;
  document.querySelector('meta[name="viewport"]').setAttribute('content', 'initial-scale=' + scale + ', maximum-scale=' + scale + ', minimum-scale=' + scale + ', user-scalable=no');
  var reSize = _.debounce(function() {
      var deviceWidth = document.documentElement.clientWidth < 1024 ? 1024 : document.documentElement.clientWidth;
      // 整个屏幕的宽度 是 19.2 个字 一个字就是 1rem
      document.documentElement.style.fontSize = (deviceWidth / 19.20) + 'px';
  }, 50);
 
  reSize();
  window.onresize = reSize;
})();
ReactDOM.render(
    <Provider store={store}>
      <LocaleProvider locale={zh_CN}>
        <App />
      </LocaleProvider>
    </Provider>,
   document.getElementById('root')
);