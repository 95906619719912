// npm start
//react与antd结合使用实现分页打印功能以及插件 npm install --save react-to-print; 引用 import ReactToPrint from ‘react-to-print’; https://blog.csdn.net/weixin_45343326/article/details/102676756

export const width = document.body.clientWidth;
export const height = document.body.clientHeight;

export const admin_username = "faadmin";
// 后勤部门
export const support_team_role_id = 2;

// 列表显示 一页显示的记录数量
export const list_page_size = 100;

/**
 * 菜单类型
 * url: 接口;
 * menu: 菜单
 * page: 子页面（不在主菜单显示）
 */
export const const_menu_type = {
  "url": "1",
  "menu": "2",
  "page": "3",
  "button": "4"
};

// 左边查询条件输入条的类型
export const sider_type_name = {
    "main_query": "主页查询",
};

// 数据预处理类型
export const process_type = {
    "caseData": "01",
};

// 数据数据挖掘类型
export const dm_type = {
    "caseData": "车贷案件挖掘",
};

// '数据用途类型 01 数据建模，02 数据预测',
export const calc_type_close_price = {
    "price_changed": "价格波动",
    "price_avg":     "平均价格",
    "price_std":     "标准差",
};

//允许用在坐标轴的类型，X轴还是Y轴
export const axis_enable_type = {
    "X": "X",
    "Y": "Y",
    "XY": "XY",
};

// 发到 phthon 服务器端的 任务类型名称
export const pathon_task_type = {
    "ClosePricePlot": "ClosePricePlot",
    "BaiduStart": "BaiduStart",
    "BaiduStop": "BaiduStop",
};

// 坐标类型字典
export const axis_type = {
    "digital": "01",
    "dictionary": "02",
};

// 预测模型
export const predict_model = {
    "new_case": "01",
    "old_case": "02",
};

// 聚类算法类型
export const cluster_type = {
    "keamns": "kmeans",
    "DBSCAN": "DBSCAN",
};

// 任务运行模式：前台运行，后台运行
export const task_execute_model = {
    "frontend": "01",
    "backend": "02",
};

// kmeans 聚类算法类型
export const kmeans_type = {
    "keamns_common": "01",
    "keamns_plus": "02",
};

// let dicts = [{code:"", name:""}, {code:"01", name:"原始值"}, {code:"02", name:"大小归一"}, {code:"03", name:"均值归一"}, {code:"04", name:"方差归一"}];  //
// 聚类 每个字段的 数值的归一算法
export const ClusterFieldCalcType = {
    "original": "01",
    "max_min":  "02",
    "mean_one": "03",
    "std_one":  "04",
};

// 状态编码
export const python_runing_status = {
    failed: 0,
    successed: 1,
    ongoing: 2,
    idle: 3,
};

// 字段定义类型
export const field_type = {
    // 字符串的精确匹配
    string: "01_01",
    // 字符串的精确匹配，不参与查询条件的生成，例如股票代码传到前端。用于定位股票代码所对应的表。
    string_noquery: "01_11",
    integer: "01_02",
    datetime: "01_03",
    dateonly: "01_04",
    float: "01_06",

    // 可同时输入多个字符串，用“/”隔开表示 输入多个项目。每个项目都是用“/”隔开
    string_multi: "02_01",

    codedict: "03_01",
    namedict: "03_02",

    // 字符串的 like 匹配
    text: "03_04",

    // 一级字典
    thirddict: "03_06",
    // 一级字典 - 仅仅是为了引导二级字典查询条件的输入，不参与查询条件的生成
    thirddict_noquery: "03_08",
    // 自定义的一级字典
    thirddict_selfdefined: "03_10",

    // 二级字典
    thirddict_child: "03_07",
};

// 列表字段定义类型
export const field_type_list = {
    string: "01_01",
    number: "01_02",
    datetime: "01_03",
    dateonly: "01_04",

    // 字典显示
    thirddict: "03_06",
    // 下拉框字典
    thirddict_selection: "03_08",
    // 下拉框字典 - 自定义
    thirddict_selection_customized: "03_09",

    // 二级字典
    thirddict_child: "03_07",

    // 个人图片显示
    picture_member: "00_00",
    // 视频显示
    video_member: "00_10",


    // 自定义显示 - 显示的render都自己定义，一般用于定义操作列（增加，删除按钮等）
    // 一个列表目前只能定义一个
    customized_display: "03_10",
    // 自定义显示字符串，以字符串的形式显示，一个列表可以定义多个，
    customized_string: "03_11",
    // 太长字符串气泡显示
    tip_string: "03_12"
};

// 数据列表类型
export const data_list_type = {
    // 未定义的显示列表
    unknown_list: "00",
    // 自定义显示列表
    self_defined: "01",
    // 标准列表显示
    standard_list: "02",
    // 标准列表显示，同时又支持反馈状态显示
    standard_status_list: "02_1",
    // 统计数据列表
    stasti_list: "03",
    // 数据挖掘计算结果图
    dm_diagram: "04",
    // 相关系数列表
    corr_list: "05",
};

// 图片来源站点的类型
export const picture_source_type = {
    PHP_image: "01",
    QiNiu_image: "01",
};

const get = (path) => require(`../assets/imgs/${path}`)
// 全局logo
export const logo = get('common/logo.png');
// logo 蓝色
// export const logo_blue = get('common/logo_blue.png');
// 个人头像
export const default_headImg = get('common/default_headImg.png');
// 箭头向右 创建比赛
// export const icon_arrow_right = get('icon/s_arrow_right.png');

export const icon_search = get('icon/search.png');

//地址定位标志
export const location = get('selection/location.png');
// 垃圾桶 删除
export const remove = get('icon/icon_remove.png');
