import store from '../';
import { getAuthState } from '.';
import { cookieUtil, get, fetchSuccess } from '../../utils/Tools';
import consts from '../../consts';
import _ from 'lodash';
// import { getMunityHeadimg } from '../../consts/img';
import * as otherConsts from '../../consts/otherConsts';

export const cookies_login_key = 'PK_LG_KEY';

const initialState = {
  // 用户id 如果 < 0 则表示没有登陆
  memberid: -1,
  username: "",
  is_admin: 0,
  role_id: 0,
  // 用户（菜单）信息是否加载完成
  authComplete: false,
  // authData: consts.menu.menu_list
  // 菜单列表
  menu_list: [],
  // 用户授权菜单
  user_url_list:[],
  // 权限列表 此处路由表没有用，因为路由表可以保存在服务器，由服务器决定路由对应的显示组件，没有必要保存在本地Redux
  // route_list: [],
  // 接口列表
  action_list: [],
  // 用户权限
  cms_auth_role: {}
};

/**
* 设置Redux数据
*/
const setReduxData = (data) => ({
  type: types.SET_REDUX_DATA,
  data
})

// action types
export const types = {
  // 设置redux数据
  SET_REDUX_DATA: "AUTH/SET_STORE_DATA",
};

// action creators
export const actions = {
  requestMenuRoleList: (memberid) => {
    return (dispatch, getState) => {
      get(consts.API.getMenuRoleList, {
        memberid
      }).then(res => {

        if (fetchSuccess(res)) {
          const data = res.data.data;
          const menu_list = data.menu_list;
          const result = {
            username: data.username,
            is_admin: data.is_admin,
            role_id: data.role_id,
            menu_list,
            // route_list: data.route_list,
            action_list: data.action_list,
            cms_auth_role: data.cms_auth_role
          }
          const user_url_list = getAuthState(getState()).user_url_list;

          // 定义闭包 二层递归函数
          var loopPath = function(data) {
              for(let i = 0; i < data.length; i ++) {
                const item = data[i];
                if (item.menu_type !== otherConsts.const_menu_type.url && item.path) {
                  user_url_list.push(item.path);
                }
                if (item.children) {
                  loopPath(item.children)
                }
              }
          }
          // 启动递归函数调用
          loopPath(data.route_list);

          Object.assign(result, { user_url_list })
          window.sessionStorage.setItem('user_url_list', JSON.stringify(user_url_list));
          // window.localStorage.setItem('user_menu_list', JSON.stringify(menu_list));

          // const old_stores = getAuthState(store.getState());
          // dispatch 也会触发框架调用组件的装饰器 @WidthAuth 从这个角度看 Redux 是有用的
          dispatch(setReduxData(result))
          // const new_stores = getAuthState(store.getState());
        }

        // const old_stores = getAuthState(store.getState());
        dispatch(setReduxData({
          authComplete: true
        }));
        // const new_stores = getAuthState(store.getState());
        // let aaa = new_stores;
      })

    }
  },

  // getMenufromStorage: (memberid) => {
  //   return (dispatch, getState) => {
  //       let menu_list = window.localStorage.getItem('user_menu_list');
  //       if (menu_list) {
  //           menu_list = JSON.parse(menu_list);
  //           dispatch(setReduxData({
  //               action_list: menu_list,
  //           }));
  //       }
  //   }
  // },

  loginSuccess: (userInfo) => {
    return(dispatch, getState) => {
      dispatch(setReduxData({
          memberid: userInfo.memberid,
      }))
      // 获取菜单
      //  没有diapatch 好像 actions.requestMenuRoleList 不会被执行
      // actions.requestMenuRoleList(userInfo.memberid);
      dispatch(actions.requestMenuRoleList(userInfo.memberid))
    }
  },

  // 保存用户信息到缓存 cookie 目前只有 {memberid: res.id, mobilephone: res.mobile}
  saveUserinfoToCache: (userInfo) => {
    return(dispatch, getState) => {
      // 如果用户刷新了页面，那么通过redux存储的全局数据就会被重置。一般我们可以使用sessionStorage或者localStorage来达到数据存储的要求
      window.localStorage.setItem(cookies_login_key, JSON.stringify(userInfo));
      cookieUtil.set(cookies_login_key, cookies_login_key, 1);
    }
  },
  logout: () => {
    return (dispatch, getState) => {
      window.localStorage.removeItem(cookies_login_key);
      cookieUtil.delete(cookies_login_key)
      window.location.href = consts.router.login();
    }
  },
  changePwd: () => {
        return (dispatch, getState) => {
            // 如果清除 cookie 则会跳转到登录页面
            // window.localStorage.removeItem(cookies_login_key);
            // cookieUtil.delete(cookies_login_key)
            window.location.href = consts.router.changePwd();
        }
  }
};

// reducers
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_REDUX_DATA:
      return Object.assign({}, state, {...action.data});
    default:
      return state;
  }
};

export default reducer;

// selectors
export const getLoggedUser = state => state.auth;

/**
 * 获取用户 create id
 */
export const getCreateid = () => {
  return getAuthState(store.getState()).memberid;
}
/**
 * 获取用户头像
 */
export const getHeadimg = () => {
  return otherConsts.default_headImg;
}

/**
 * 获取用户授权的url
 */
export const getUserAuthUrls = () => {
  const stores = getAuthState(store.getState());
  return _.get(stores, 'user_url_list');
}


// 是否获得用户信息
export const isLoggedIn = () => {
  const authStore = getAuthState(store.getState());
  // 页面重新获得用户信息（包含菜单）成功
  if (authStore.memberid > 0) {
    return true;
  }
  // 判断cookies中是否有存在登录key值
  const cookiesLoginkey = cookieUtil.get(cookies_login_key);
  // 获取对应的 localStorage JSON值（用户信息）;
  if (cookiesLoginkey && window.localStorage.getItem(cookiesLoginkey)) {
    const userInfo = JSON.parse(window.localStorage.getItem(cookiesLoginkey));
    // 如果用户刷新了页面，那么通过redux存储的全局数据就会被重置。一般我们可以使用sessionStorage或者localStorage来达到数据存储的要求。
    // 通过redux-persist实现redux持久化本地数据存储。https://blog.csdn.net/weixin_43295397/article/details/89209597
    store.dispatch(actions.loginSuccess(userInfo));
  return true;
  }
  else {
    window.localStorage.removeItem(cookies_login_key);
  }

  return false;
}

// 刷新菜单
// export const refreshMenu = () => {
//     store.dispatch(actions.getMenufromStorage());
// }
