// @ts-ignore
import axios from 'axios';
import { doStartRequest, doFinishRequest } from '../redux/modules/application';
import _ from 'lodash';
import { log, fetchSuccess } from './Tools';
// import { doAlert } from '../redux/modules/ui';

let requestConfig = {};
/** 设置拦截器 */
// @ts-ignore
axios.interceptors.request.use(function (config) {

  if (process.env.NODE_ENV === 'development') {
    log(`================ 拦截器请求: ${config.url}`);
    log(config);
    log('====================================');
  }

  // doShowLoading();
  // Do something before request is sent
  // let openid = localStorage.getItem('openid');
  // let staff = JSON.parse(sessionStorage.getItem('staff'));
  // if (openid && staff) {
  //     config.headers.openid = openid;
  //     config.headers.userid = staff.id;
  //     requestConfig = config;
  // }

  return config;
}, function (error) {

  process.env.NODE_ENV === 'development' && log('请求失败');
  return Promise.reject(error);
});

// Add a response interceptor
// @ts-ignore
axios.interceptors.response.use(function (response) {
  // doHideLoading();
  if (process.env.NODE_ENV === 'development') {
    log(`=============== 请求响应成功: ${response.request.responseURL}`);
    log(response)
    log('====================================');
  }
  // Do something with response data
  return response;
}, function (error) {
  // doHideLoading();
  if (process.env.NODE_ENV === 'development') {
    log('=============== 响应失败 =====================');
    log(error.response);
    log(error.status)
    log(error.message)
    log('====================================');
  }

  return Promise.reject(error);
});

/**
 * get请求
 * @param {string} url
 */
export const get = async (url, params = {}) => {
  try {
    if (url == undefined || url.length < 1) {
        return Promise.reject("url为空");
    }

    log('请求参数');
    log(params);
    doStartRequest();
    const res = await axios({
      url: url,
      method: 'get',
      params: params
    });

    if (fetchSuccess(res) || res.status + '' === '200') {

      doFinishRequest();
      return Promise.resolve(res)

    } else {
      // 失败
      // doAlert(res.data.info, 'error');
      doFinishRequest();
      return Promise.reject(res);
    }
  } catch(err) {
      doFinishRequest();
  }
}

/**
 * get_yxdapi 专门为银信达做的API请求 - 因为返回数据结构不同
 * @param {string} url
 */
export const get_yxdapi = async (url, params = {}) => {
    try {
        if (url == undefined || url.length < 1) {
            return Promise.reject("url为空");
        }

        doStartRequest();
        const res = await axios({
            url: url,
            method: 'get',
            params: params
        });
        doFinishRequest();
        return Promise.resolve("");

        // if (res != undefined) {
        //     return Promise.resolve(res)
        //
        // } else {
        //     return Promise.reject("");
        // }
    } catch(err) {
        doFinishRequest();
    }
}

/**
* post请求
* @param {string} url
* @param {object} requestData
*/
export const post = async (url, requestData = {}) => {
  try {
    if (url == undefined || url.length < 1) {
        return Promise.reject("url为空");
    }
    doStartRequest();
    log('post 请求参数');
    log(requestData)
    const formData = new FormData();
    try {
        const keys = Object.keys(requestData);
        for(let k of keys) {
            formData.append(k, requestData[k]);
        }
    } catch(err) {

    }
    const res = await axios.post(url, formData);
    if (fetchSuccess(res)) {
      // doAlert(_.get(res, 'data.info') || '请求成功', 'success');
      doFinishRequest();
      return Promise.resolve(res)
    } else {
      // 失败
      // doAlert(res.data.info, 'error');
      doFinishRequest();
      return Promise.reject(res)
    }
  } catch(err) {
      // 失败
      doFinishRequest();
      return Promise.reject(err)
  }
}

/**
* post请求
* @param {string} url
* @param {object} requestData
*/
export const formPost = (url, requestData = {}) => {
  if (url == undefined || url.length < 1) {
      return 'url为空';
  }

  doStartRequest();

  let headers = Object.assign({}, requestConfig.headers, {
    'Content-Type': 'application/x-www-form-urlencoded'
  })
  // @ts-ignore
  return axios({
    url: url,
    method: 'post',
    data: requestData,
    headers,
    transformRequest: [function (data) {
      let ret = ''
      for (let it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      }
      return ret
    }]
  })

}

