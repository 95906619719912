import React, { Component } from "react";
import { getUserAuthUrls } from "../redux/modules/auth";
import { createSkeleton } from "./Tools";

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
        userUrls: []
      };
    }

    componentDidMount() {
      importComponent().then((mod) => {
        this.setState({
          // 同时兼容ES6和CommonJS的模块
          component: mod.default ? mod.default : mod,
          userUrls: getUserAuthUrls()
        });
      });
    }

    render() {
      const C = this.state.component;
      return C ? <C {...this.props} /> : <>
      {/* 网络慢加载骨架屏 */}
        {createSkeleton(8)}
      </>;
    }
  }

  return AsyncComponent;
}
